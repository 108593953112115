<header>
    <div class="header-top" (click)="redirectToDashboard($event)">
        <div class="header-top-right">
            <careweb-header-dropdown id="dv_user_info" class="header-top-right-user" [submenu]="hotlineDropDown"
                [hidden]="!isAuthorized()" [leaveWhenMouseLeave]=rightClicked (click)="searchPsInput=false;">
                <span class="name text-capitalize" name="spn_user_name">PS HOTLINE</span>
                <span class="company text-capitalize" name="spn_pcp_name" *ngIf="numeroPs">{{ numeroPs }}</span>
                <span class="" name="spn_pcp_name" *ngIf="!numeroPs">Aucun PS sélectionné</span>
                <i class="icon-myfont-arrowdown"></i>
                <ul #hotlineDropDown class="submenu">
                    <li *ngIf="!searchPsInput"><a id="a_rechercher_ps" (click)="showSearchPsInput($event)"
                            class="dropdown-item">Rechercher PS</a></li>
                    <li *ngIf="searchPsInput">
                        <div class="row m-0 justify-content-center">
                            <input type="text" id="a_rechercher_ps_input" class="col-11" autocomplete="off"
                                (blur)="onBlurDetect()" (contextmenu)="onRightClick()" (click)="stopPropagation($event)"
                                (keydown.enter)="onKeyDown($event)" onKeyPress="if(this.value.length==9) return false;"
                                oninput="this.value=this.value.replace(/[^\d]/,'');"
                                onpaste="if(this.value.length==9) return false;" maxlength="9" />
                        </div>
                    </li>
                    <li><a id="a_desactivate_filter" (click)="desactivatePsFilter()"
                            [class]="numeroPs?'dropdown-item':'dropdown-item disabled'">Désactiver PS</a></li>
                    <li>
                        <a id="a_add_intervention" (click)="goToAddIntervention()" [routerLink]="['/add_intervention']"
                            target="_blank" class="add_intervention"
                            [ngClass]="numeroPs ? 'dropdown-item':'dropdown-item disabled'">Ajouter une
                            intervention</a><!-- (click)="goToAddIntervention()" -->
                    </li>
                    <li>
                        <a id="a_my_profile" [routerLink]="['/ps-details/'+idPs]"
                            [class]="numeroPs?'dropdown-item':'dropdown-item disabled'">Voir la fiche PS </a>
                    </li>
                    <hr class="divider">
                    <li>
                        <a class="dropdown-item parent-item fw-bold" >Derniers PS consultés</a></li>
                    <ul *ngIf="lastPsConsulted" class="sublist">
                        <li *ngFor="let ps of lastPsConsulted" class="dropdown-item sub-item"
                            (blur)="onBlurDetect()" (contextmenu)="onRightClick()" (click)="stopPropagation($event)"> {{ps}} </li>
                    </ul>
                </ul>
            </careweb-header-dropdown>
            <careweb-header-dropdown id="dv_select_ps" class="header-top-right-user" [submenu]="dropdownListPs"
                *ngIf="isSupervisor">
                <span class="name text-capitalize" name="spn_user_name">
                    PS
                </span>
                <span class="user-type text-capitalize" name="spn_pcp_name">
                    {{selectedSupervised==-1? 'Tous les PS': selectedSupervised}}
                </span>
                <i class="icon-myfont-arrowdown"></i>
                <ul #dropdownListPs class="submenu">
                    <li><a id="a_account" (click)="activateAllPs()">Tous les PS</a></li>
                    <li *ngFor="let supervisedPs of supervisedPsList ; let i=index">
                        <a id="a_edit_password" (click)="activatePsSupervised(supervisedPs)">{{
                            supervisedPs.nomPsDelegue?supervisedPs.numeroPsDelegue+ ' -
                            '+supervisedPs.nomPsDelegue:supervisedPs.numeroPsDelegue }}</a>
                    </li>

                </ul>
            </careweb-header-dropdown>
            <careweb-header-dropdown id="dv_user_info" class="header-top-right-user" [submenu]="dropdownUser">
                <span class="name text-capitalize" name="spn_user_name"
                    [ngStyle]="{'margin-top':(currentUser && currentUser.etablissementRaisonSocial? '20px' :'28px')}">
                    {{currentUser.username}}
                </span>
                <span class="user-type text-capitalize" name="spn_pcp_name"
                    *ngIf="currentUser && currentUser.etablissementRaisonSocial">
                    {{currentUser.etablissementRaisonSocial}}
                </span>
                <i class="icon-myfont-arrowdown"></i>
                <ul #dropdownUser class="submenu">
                    <li><a id="a_account" [routerLink]="['/user-profile']">Mon compte</a></li>
                    <li>
                        <a id="a_edit_password" [routerLink]="['/update-password']">Modifier mon mot de passe</a>
                    </li>
                    <li *ngIf="!isAdminSel()">
                        <a id="a_contact" [routerLink]="['/nouveautes']">Nouveautés</a>
                    </li>
                    <li>
                        <a id="a_contact" [routerLink]="['/contact-support']">Contacter le support</a>
                    </li>
                </ul>
            </careweb-header-dropdown>

            <careweb-header-dropdown class="header-top-right-lang" [submenu]="dropdownLang" *ngIf="!showHotlineMenu"
                [hidden]="!isAuthorized() && !isAdminSel()">
                <div>
                    <span>{{ octSvc.getCurrentOctValue?.nom | uppercase }}</span> <i class="icon-myfont-arrowdown"></i>
                </div>
                <ul #dropdownLang role="menu">
                    <li role="menuitem" *ngFor="let oct of octList" role="menuitem"
                        (click)="onOctChange(oct.idOct, oct.nom, oct?.code)" class="oct.idOct" id="octSelected?.code"
                        [class.selected]="octSelected?.code === oct?.code">
                        <a>{{oct?.nom}}</a>
                    </li>
                </ul>
            </careweb-header-dropdown>
            <!-- Logout -->
            <div id="dv_header_logout" class="header-top-right-logout">
                <a id="a_header_logout" (click)="logout()"> <i class="icon-myfont-deco"></i> </a>
            </div>
        </div>
        <a class="baseline">&nbsp;</a>
    </div>

    <nav class="navbar navbar-expand-xlg header-menu">
        <div>
            <button type="button" class="navbar-toggler collapsed hamburger-header" (click)="isCollapsed = !isCollapsed"
                aria-expanded="false">
                <i class="fa fa-solid fa-bars"></i>
            </button>
            <div class="collapse navbar-collapse" [collapse]="isCollapsed">
                <ul class="nav navbar-nav me-auto">
                    <li class="nav-item" *ngIf="!showHotlineMenu && !isAdminSel()">
                        <a id="a_dashbord" class="nav-link" routerLinkActive="active"
                            [routerLink]="['/dashboard']">Dashboard</a>
                    </li>
                    <li class="nav-item dropdown" dropdown routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}" [hidden]="!isAuthorized() && !isAdminSel()"
                        *ngIf="!showHotlineMenu">
                        <a id="a_gestion_ps" class="nav-link" routerLinkActive="selected" dropdownToggle>Gestion
                            PS</a>
                        <ul *dropdownMenu class="dropdown-menu" role="menu">
                            <li role="menuitem">
                                <a id="a_search_ps" class="dropdown-item" [routerLink]="['/ps']">Rechercher un PS</a>
                            </li>
                            <li role="menuitem" [hidden]="!isAuthorized()">
                                <a id="a_add_ps" class="dropdown-item" [routerLink]="['/add-ps']">Ajouter un PS</a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item dropdown" dropdown dropdownToggle routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}" *ngIf="!showHotlineMenu && !isAdminSel()">
                        <a id="a_flux" class="nav-link">Flux</a>
                        <ul *dropdownMenu class="dropdown-menu" role="menu">
                            <li role="menuitem">
                                <a id="a_lots" class="dropdown-item" routerLinkActive="active"
                                    [routerLink]="['/lots']">Liste des lots</a>
                            </li>
                            <li role="menuitem">
                                <a id="a_arl" class="dropdown-item" [routerLink]="['/arl']">Liste des ARL</a>
                            </li>
                            <li role="menuitem">
                                <a id="a_fse" class="dropdown-item" [routerLink]="['/fse']">Liste des FSE</a>
                            </li>
                            <li role="menuitem">
                                <a id="a_rsp" class="dropdown-item" [routerLink]="['/rsp']">Liste des RSP</a>
                            </li>
                            <li role="menuitem">
                                <a id="a_rejets" class="dropdown-item" [routerLink]="['/rejet']">Liste des Rejets</a>
                            </li>
                            <li role="menuitem">
                                <a id="a_mouvements-financiers" class="dropdown-item"
                                    [routerLink]="['/mouvements-financiers']">Mouvements Financiers</a>
                            </li>
                            <li role="menuitem">
                                <a id="a_retours_editables" class="dropdown-item" [routerLink]="['/retours-editables']"
                                    [hidden]="!hasOctAccess()">Retours éditables</a>
                            </li>
                            <li role="menuitem">
                                <a id="a_comptabilite" class="dropdown-item"
                                    [routerLink]="['/comptabilite']">Comptabilité</a>
                            </li>
                            <li role="menuitem">
                                <a id="a_reporting" class="dropdown-item" [routerLink]="['/reporting']">Récapitulatif
                                    annuel</a>
                            </li>
                            <li role="menuitem">
                                <a id="a_loi" class="dropdown-item" [routerLink]="['/loi']">LOI</a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item dropdown" dropdown dropdownToggle routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}"
                        *ngIf="!showHotlineMenu && isAuthorized() && !isAdminSel()">
                        <!-- [hidden]="!hasOctAccess()"  -->
                        <a id="a_organismes" class="nav-link" routerLinkActive="active">Organismes</a>
                        <ul *dropdownMenu class="dropdown-menu" role="menu">
                            <li role="menuitem">
                                <a id="a_organismes" class="dropdown-item" [routerLink]="['/organismes']">Rechercher un
                                    organisme</a>
                            </li>
                            <li role="menuitem" [hidden]="!isAuthorized() || !hasOctAccess()">
                                <a id="a_new_organisme" class="dropdown-item" [routerLink]="['/new-organisme']">Ajouter
                                    un organisme</a>
                            </li>
                            <li role="menuitem" [hidden]="!isAuthorized() || !hasOctAccess()">
                                <a id="a_type_0" class="dropdown-item" [routerLink]="['/type0']">Rechercher un type
                                    0</a>
                            </li>
                            <li role="menuitem" [hidden]="!isAuthorized() || !hasOctAccess()">
                                <a id="a_new_organisme" class="dropdown-item" [routerLink]="['/new-type0']">Ajouter un
                                    type 0</a>
                            </li>
                        </ul>
                    </li>

                    <li *ngIf="isPsSelected() && !showHotlineMenu" class="nav-item dropdown" dropdown dropdownToggle
                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        <a id="a_comptes_oct" class="nav-link" routerLinkActive="">Rapprochement bancaire</a>
                        <ul *dropdownMenu class="dropdown-menu" role="menu">
                            <li role="menuitem">
                                <a id="a_utilisateurs_oct" class="dropdown-item" [routerLink]="['/rapp-dashboard']">
                                    Dashboard
                                </a>
                            </li>
                            <li role="menuitem">
                                <a id="a_utilisateurs_oct" class="dropdown-item" [routerLink]="['/rapp-auto']">
                                    Rapprochements automatiques
                                </a>
                            </li>
                            <li role="menuitem">
                                <a id="a_utilisateurs_oct" class="dropdown-item" [routerLink]="['/rapp-manuel']">
                                    Rapprochements manuels
                                </a>
                            </li>
                            <li role="menuitem">
                                <a id="a_utilisateurs_oct" class="dropdown-item" [routerLink]="['/rapp-valide']">
                                    Rapprochements validés
                                </a>
                            </li>
                            <li [hidden]="!isOxlinUser()">
                                <a id="rapp_bank_account" class="dropdown-item" [routerLink]="['/rapp-bank-account']">
                                    Mes comptes bancaires
                                </a>
                            </li>
                            <li [hidden]="!isEbicsUser()" *ngIf="isPsSelected()">
                                <a id="ebics_account" class="dropdown-item" [routerLink]="['/ebics-account']">
                                    Mes comptes bancaires
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item dropdown" dropdown dropdownToggle routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}"
                        *ngIf="!showHotlineMenu && !isAuthorized() && !isAdminSel()">
                        <a id="a_organismes" class="nav-link" routerLinkActive="active"
                            [routerLink]="['/organismes']">Organismes</a>
                    </li>
                    <li class="nav-item dropdown" dropdown dropdownToggle routerLinkActive="active"
                        [hidden]="!isAuthorized() || !hasOctAccess('editeur')" *ngIf="!showHotlineMenu">
                        <a id="a_editors" class="nav-link">Éditeurs</a>
                        <ul *dropdownMenu class="dropdown-menu" role="menu">
                            <li role="menuitem">
                                <a id="a_editors_search" class="dropdown-item" [routerLink]="['/editeurs']">Rechercher
                                    un éditeur</a>
                            </li>
                            <li role="menuitem">
                                <a id="a_add_editor" class="dropdown-item" [routerLink]="['/ajout-editeur']">Ajouter un
                                    éditeur</a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item dropdown" dropdown dropdownToggle routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}" [hidden]="!isAuthorized()" *ngIf="!showHotlineMenu">
                        <a id="a_comptes_oct" class="nav-link" routerLinkActive="">Comptes
                            OCT</a>
                        <ul *dropdownMenu class="dropdown-menu" role="menu">
                            <li role="menuitem">
                                <a id="a_utilisateurs_oct" class="dropdown-item" [routerLink]="['/users']">
                                    Rechercher un compte
                                </a>
                            </li>
                            <li role="menuitem">
                                <a id="a_utilisateurs_oct" class="dropdown-item" [routerLink]="['/user-oct-details']">
                                    Ajouter un utilisateur
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li *ngIf="!rappConditionLoading && ((((showRappBancMenu && !isPsSelected()) || (isEbicsUser() && !isPsSelected())) && currentUser.userPreferences.rapproActif && isSupervisor==false) || (isSupervisor && (rappVisible || selectedSupervised==-1) && isRappVisible()))"
                        class="nav-item dropdown" dropdown dropdownToggle routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}">
                        <a id="a_comptes_oct" class="nav-link" routerLinkActive=""
                            (click)="onMenuRappClick($event)">Rapprochement bancaire</a>
                        <ul *dropdownMenu class="dropdown-menu" role="menu">
                            <li role="menuitem" [hidden]="!currentUser.userPreferences.rapproActif">
                                <a id="a_utilisateurs_oct" class="dropdown-item" [routerLink]="['/rapp-dashboard']" >
                                    Dashboard
                                </a>
                            </li>
                            <li role="menuitem" [hidden]="!currentUser.userPreferences.rapproActif">
                                <a id="a_utilisateurs_oct" class="dropdown-item" [routerLink]="['/rapp-auto']" >
                                    Rapprochements automatiques
                                </a>
                            </li>
                            <li role="menuitem" [hidden]="!currentUser.userPreferences.rapproActif">
                                <a id="a_utilisateurs_oct" class="dropdown-item" [routerLink]="['/rapp-manuel']" >
                                    Rapprochements manuels
                                </a>
                            </li>
                            <li role="menuitem" [hidden]="!currentUser.userPreferences.rapproActif">
                                <a id="a_utilisateurs_oct" class="dropdown-item" [routerLink]="['/rapp-valide']"  >
                                    Rapprochements validés
                                </a>
                            </li>
                            <li role="menuitem"
                                [hidden]="!showRappBancMenu || !currentUser.userPreferences.rapproActif">
                                <a id="a_utilisateurs_oct" class="dropdown-item" [routerLink]="['/rapp-bank-account']" >
                                    Mes comptes bancaires
                                </a>
                            </li>
                            <li role="menuitem" [hidden]="!isEbicsUser()">
                                <a id="a_utilisateurs_oct" class="dropdown-item" [routerLink]="['/ebics-account']" >
                                    Mes comptes bancaires
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item dropdown" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                        [hidden]="!isAuthorized() || !hasOctAccess() || showHotlineMenu" *ngIf="!showHotlineMenu">
                        <a id="a_hotline" class="nav-link" routerLinkActive="" target="_blank"
                            [routerLink]="['/hotline']">Hotline</a>
                    </li>
                    <li class="nav-item dropdown" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                        [hidden]="!isAuthorized()" *ngIf="showHotlineMenu">
                        <a id="a_hotline_dashboard" class="nav-link" [routerLink]="['/hotline-dashboard']">Dashboard</a>
                    </li>
                    <li class="nav-item dropdown" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                        [hidden]="!isAuthorized()" *ngIf="showHotlineMenu">
                        <a id="a_hotline" class="nav-link" [routerLink]="['/hotline']">Rechercher une intervention</a>
                    </li>
                    <li class="nav-item dropdown" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                        [hidden]="!isAuthorized()" *ngIf="showHotlineMenu">
                        <a id="a_hotline" class="nav-link" [routerLink]="['/hotline-parameters']">Administration des
                            paramètres</a>
                    </li>
                    <li class="nav-item dropdown" dropdown dropdownToggle routerLinkActive="active"
                        [hidden]="!isAuthorized()" *ngIf="!showHotlineMenu">
                        <a id="a_dashbord_admin" class="nav-link" routerLinkActive="">Administration</a>
                        <!-- TODO Change menu not click on same place for display submenu and access to dashboard admin-->
                        <ul *dropdownMenu class="dropdown-menu" role="menu">
                            <li class="dropdown-header">Flux</li>
                            <li role="menuitem">
                                <a id="a_dashboard_admin" class="dropdown-item"
                                    [routerLink]="['/dashboard-admin']">Tableau de bord</a>
                            </li>
                            <li role="menuitem">
                                <a id="a_migration_oct" class="dropdown-item" *ngIf="isAdminAuthorized()"
                                    [routerLink]="['/migration-oct']">Migration des comptes OCT</a>
                            </li>
                            <div class="dropdown-divider"></div>
                            <li class="dropdown-header">Rapprochement bancaire</li>
                            <li role="menuitem">
                                <a id="a_suivi_rapp" class="dropdown-item" [routerLink]="['/suivi-rapp']">Suivi des flux
                                    PS</a>
                            </li>
                            <li role="menuitem">
                                <a id="a_suivi_rapp" class="dropdown-item" [routerLink]="['/perf-rapp']">Performance du
                                    rapprochement</a>
                            </li>
                            <li role="menuitem">
                                <a id="a_dict-rule-rapp" class="dropdown-item" *ngIf="isAdminAuthorized()"
                                    [routerLink]="['/dict-rule-rapp']">Dictionnaires de rappro. auto</a>
                            </li>
                            <li role="menuitem">
                                <a id="a_dict-rule-rapp" class="dropdown-item" *ngIf="isAdminAuthorized()"
                                    [routerLink]="['/logs-rapprochement']">Exécutions du rappro. auto (logs)</a>
                            </li>
                            <div class="dropdown-divider"></div>
                            <li class="dropdown-header" *ngIf="isAdminAuthorized()">EBICS</li>
                            <li role="menuitem">
                                <a id="a_abonnement_ebics" class="dropdown-item" *ngIf="isAdminAuthorized()"
                                    [routerLink]="['/abonnements-ebics']">Abonnements EBICS (config)</a>
                            </li>
                            <li role="menuitem">
                                <a id="suivi_ebics" class="dropdown-item" *ngIf="isAdminAuthorized()"
                                    [routerLink]="['/suivi-ebics']">Suivi du EBICS Client (logs)</a>
                            </li>
                            <div class="dropdown-divider"></div>
                            <li class="dropdown-header" *ngIf="isAdminAuthorized()"> Contenus de la plateforme</li>
                            <li role="menuitem">
                                <a id="admin-content" class="dropdown-item" *ngIf="isAdminAuthorized()"
                                    [routerLink]="['/admin-content']"> Modification des contenus </a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item dropdown" dropdown dropdownToggle routerLinkActive="active"
                        [hidden]="!hasSelAccess()" *ngIf="!showHotlineMenu">
                        <a id="a_services" class="nav-link">Services en ligne</a>
                        <ul *dropdownMenu class="dropdown-menu" role="menu">
                            <li role="menuitem">
                                <a id="a_organizations_search" class="dropdown-item"
                                    [routerLink]="['/organization-list']">Paramétrages AMC/OTP</a>
                            </li>
                            <li role="menuitem">
                                <a id="a_services_search" class="dropdown-item"
                                    [routerLink]="['/tracability-flux-list']">Traçabilité des Flux</a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</header>
<div *ngIf="showDetailModal">
    <div class="modal modal-show fade show" id="modalDeleteType" tabindex="-1">
        <div class="modal-dialog modal-md" role="document">
            <div class="modal-content">
                <div class="inmodal">
                    <div class="modal-header pb-1">
                        <h3 class="modal-title"><i class="fa fa-bell text-info-debug mr-1" aria-hidden="true"></i>
                            Veuillez sélectionner un PS</h3>
                        <a><i class="fa fa-close" (click)="cancel()"></i></a>
                    </div>
                    <div class="modal-body pb-0">
                        <div class="form-block">
                            <fieldset class="container-fluid">
                                <div class="row">
                                    <div class="col-sm-12 col-form-label alert alert-info">
                                        Vous pouvez accéder au service de rapprochement bancaire une fois que vous aurez
                                        sélectionné un PS avec le rapprochement bancaire activé et visible
                                    </div>
                                    <div class="col-sm-12 ">
                                        <div class="row form-group">
                                            <div class="col-sm-3">Sélectionner un PS *</div>
                                            <div class="col-sm-5">
                                                <ng-select name="numeroPScomboBox" id="numeroPScomboBox"
                                                    placeholder="Sélectionner un PS" [searchable]="false"
                                                    [clearable]="false" (change)="onChangePsSupervised($event)"
                                                    [(ngModel)]="selectedPs" #psSelect="ngModel" required>
                                                    <ng-option
                                                        *ngFor="let numeroPScomboBox of supervisedPsListwithRappActiveVisible"
                                                        [value]="numeroPScomboBox">
                                                        {{ numeroPScomboBox.numeroPsDelegue }}
                                                    </ng-option>
                                                </ng-select>
                                            </div>
                                            <div class="col-sm-4"></div>
                                        </div>

                                    </div>
                                </div>
                                <div class="button-bar control">
                                    <button type="button" (click)="cancel()" class="btn btn-default"> Fermer
                                    </button>
                                    <button type="button" (click)="validerActivatePs()" class="btn btn-primary"> Valider
                                    </button>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                    <div class="modal-footer">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="showModalRappInactive">
    <div class="modal modal-show fade show" id="modalDeleteType" tabindex="-1">
        <div class="modal-dialog modal-md" role="document">
            <div class="modal-content">
                <div class="inmodal">
                    <div class="modal-header pb-1">
                        <h3 class="modal-title"><i class="fa fa-bell text-info-debug mr-1" aria-hidden="true"></i>
                            Veuillez sélectionner un PS</h3>
                        <a><i class="fa fa-close" (click)="cancelModalRappInactive()"></i></a>
                    </div>
                    <div class="modal-body pb-0">
                        <div class="form-block">
                            <fieldset class="container-fluid">
                                <div class="row">
                                    <div class="col-sm-12 col-form-label alert alert-info">
                                        Le service de rapprochement bancaire est accessible uniquement pour la liste des
                                        PS suivante :
                                        <div class="string-list">
                                            <span *ngFor="let ps of psWithRappActiveAndVisible" class="string-item"><a
                                                    (click)="selectPsSupervised(ps)">{{ ps }}</a></span>
                                        </div>
                                    </div>

                                </div>
                                <div class="button-bar control">
                                    <button type="button" (click)="cancelModalRappInactive()" class="btn btn-default">
                                        Fermer
                                    </button>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                    <div class="modal-footer">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>